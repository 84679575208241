<script setup lang="ts">
import { Routes } from "~/utils/routes";
import VLandingWrapper from "~/containers/landing/VLandingWrapper.vue";

const route = useRoute();
</script>

<template>
  <header
    class="v1-header"
    :class="{ 'v1-header_landing': route.path === Routes.Landing }"
  >
    <VInstallAppBannerLocal />
    <VLandingWrapper class="container">
      <NuxtLink :to="Routes.Landing" class="logo">
        В1 – ПЕРВЫЙ ВЫБОР
      </NuxtLink>
      <div class="navigation">
        <template v-if="route.path === Routes.Landing">
          <NuxtLink
            :to="Routes.Terms"
            class="our-card"
          >Выгодная карта</NuxtLink>
          <a href="#landing-profits"> В чем выгода </a>
          <a href="#landing-map"> Магазины на карте </a>
          <a href="#landing-contacts"> Связаться с нами </a>
        </template>
        <template v-else-if="route.path === Routes.Terms">
          <a href="#landing-terms-in-phone">
            Активировать или выпустить карту
          </a>
          <a href="#landing-terms-faq"> Ответы на вопросы </a>
          <NuxtLink :to="Routes.Contacts">
            Связаться с нами
          </NuxtLink>
        </template>
      </div>
    </VLandingWrapper>
  </header>
</template>

<style scoped lang="postcss">
.v1-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  z-index: 10;

  :deep(.install-app-banner__wrapper) {
    position: relative;
  }

  &_landing {
    background-color: transparent;
    flex-direction: column;

    .logo {
      display: none;
    }

    .navigation {
      a {
        &.our-card {
          display: inline;
        }
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    height: var(--v1-landing-header);
  }

  .logo {
    color: var(--v1-landing-color-red);
    text-decoration: none;
    line-height: 1em;
    font-weight: 900;
    font-size: 22px;
    white-space: nowrap;
  }

  .navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    a {
      font-weight: 500;
      text-decoration: none;
      font-size: 15px;
      white-space: nowrap;
      display: none;

      &:hover {
        color: var(--v1-landing-color-red);
      }

      &.our-card {
        background-color: var(--v1-landing-color-red);
        color: white;
        border-radius: 2px;
        padding: 4px 16px;

        &:hover {
          background-color: white;
          color: var(--v1-landing-color-red);
        }
      }
    }
  }
}

@media (--pl-viewport-from-m) {
  .v1-header {
    &_landing {
      background-color: white;

      .logo {
        display: inline;
      }

      .container {
        justify-content: space-between;
      }
    }
  }
}

@media (--pl-viewport-from-l) {
  .v1-header {
    .container {
      justify-content: start;
    }

    .logo {
      font-size: 16px;
      margin-right: 8rem;
    }

    .navigation {
      a {
        font-size: 14px;
        display: inline;
      }
    }
  }
}
</style>
